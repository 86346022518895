import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface LoggedState {
  loggedIn: boolean;
  authToken: string | null;
}

const initialState: LoggedState = {
  loggedIn: false,
  authToken: null,
};

export const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    logOut: (state) => {
      state.loggedIn = false;
      localStorage.removeItem("token");
    },
    logIn: (state, action: PayloadAction<string>) => {
      state.loggedIn = true;
      localStorage.setItem("token", action.payload);
    },
    loadFromStore(state) {
      state.authToken = localStorage.getItem("token");
    },
  },
});

export const { logOut, logIn, loadFromStore } = logSlice.actions;

export default logSlice.reducer;
