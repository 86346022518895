import styles from "./User.module.scss";
import Icons from "../../icons/icons";
import globalStyles from "../../App.module.scss";
import React, {forwardRef, Ref, useEffect, useImperativeHandle, useState} from "react";
import { EditableClinicProps, EditableClinicRef } from "../../types/types";

const EditableClinic = forwardRef((props: EditableClinicProps, ref: Ref<EditableClinicRef>) => {
    const [isClinicEditing, setIsClinicEditing] = useState<boolean>(false);
    const [changedName, setChangedName] = useState<string>(props.clinic.name);

    useImperativeHandle(ref, () => ({
        isClinicEditing: isClinicEditing,
        setIsClinicEditing: setIsClinicEditing,
        editableClinic: {
          changedName: changedName,
          prevName: props.clinic.name,
          id: props.clinic.id,
        },
    }));

    useEffect(() => {
        return () => {
            props.setIsOneClinicBeingEdited(false);
        };
    }, []);

    const onCloseEditingClinic = () => {
        setIsClinicEditing(false);
        props.setIsOneClinicBeingEdited(false);
        props.setIsAlertForEditedClinicShown(false);
    };

    const onEditClinic = () => {
        if (props.isOneClinicBeingEdited) {
            console.log("cannot be edited");
        } else {
            setIsClinicEditing(true);
            props.setIsOneClinicBeingEdited(true);
        }
    };

    const handleChangeClinicName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangedName(event.target.value);
        if (props.isAlertForEditedClinicShown && props.isClinicNameNotUnique(changedName)) {
            props.setIsAlertForEditedClinicShown(false);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && changedName !== "") {
            props.onSaveEditedClinic(changedName, props.clinic.id, props.clinic.name);
        }
    };

    return (
        <>
            {!isClinicEditing ? (
                <div
                    className={styles.clinicItem}

                >
                    <p className={styles.clinicName}>{props.clinic.name}</p>
                    <>
                        <img
                            src={Icons.Pencil}
                            alt="Pencil icon"
                            className={globalStyles.editButton}
                            onClick={() => onEditClinic()}
                        />
                        <img
                            src={Icons.Trash}
                            alt="Trash icon"
                            className={globalStyles.deleteButton}
                            onClick={() => props.onDeleteClinic(props.clinic)}
                        />
                    </>
                </div>
            ) : (
                <div className={styles.editClinicField}>
                    <div>
                        <input
                            className={styles.editClinicInput}
                            name="clinicName"
                            type="text"
                            defaultValue={props.clinic.name}
                            id="name"
                            onChange={handleChangeClinicName}
                            onKeyDown={handleKeyDown}
                        />
                        <p className={props.isAlertForEditedClinicShown ? globalStyles.alertMessage : globalStyles.hiddenAlertMessage}>
                            {props.sameClinicNameAlert}
                        </p>
                    </div>
                    <img
                        src={Icons.Tick}
                        alt="Tick icon"
                        className={globalStyles.tickButton}
                        onClick={(changedName !== "" && props.isAlertForEditedClinicShown === false && props.clinic.name !== changedName) ? () => props.onSaveEditedClinic(changedName, props.clinic.id, props.clinic.name) : undefined}
                    />
                    <img
                        src={Icons.Close}
                        alt="Close icon"
                        className={globalStyles.closeButton}
                        onClick={onCloseEditingClinic}
                    />
                </div>
            )}
        </>
    );
});

EditableClinic.displayName = "Editable Clinic";

export default EditableClinic;