import React from "react";
import { Patient } from "../../types/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Icons from "../../icons/icons";
import globalStyles from "../../App.module.scss";
import styles from "./PatientCard.module.scss";
import {
  Button,
  Flex,
  Box,
  Grid,
  Center,
  Stack,
  Space,
  Group,
  Text,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { getPatientShortName } from "../../utils/utils";
import { useActions  } from "../../app/hooks";

function PatientCard() {
  const [patient, setPatient] = useState<Patient>({
    id: 0,
    name: "",
    surname: "",
    patronymic: "",
    gender: "not selected",
    diagnosis: "",
    comment: "",
    dateOfBirth: "",
    registrationDate: "",
    clinicId: 0,
  });
  const [clinicName, setClinicName] = useState<string>("");

  const navigate = useNavigate();
  const { id } = useParams();
  const { removeTab } = useActions();

  const getPatient = async () => {
    const data = await window.electron.patient.get(parseInt(id as string));
    setPatient(data);
  };

  const getClinic = async () => {
    const data = await window.electron.clinic.get(patient.clinicId);
    setClinicName(data.name);
  };
  
  const editPatient = async () =>{
    navigate("/patient/" + patient.id +"/edit");
  };

  const deletePatient = async () => {
    window.electron.patient.remove(parseInt(id as string));
    removeTab(parseInt(id as string));
    navigate("/");
  };
  React.useEffect(() => {
    getPatient().catch(e => console.error);
  }, [id]);

  React.useEffect(() => {
    getClinic().catch(e => console.error);
  }, [patient]);

  const [
    deletePatientModalOpened,
    { open: opendeletePatientModal, close: closeDeletePatientModal },
  ] = useDisclosure(false);

  return (
    <div className={styles.patientCardFormFieldsGroup}>
      <Center maw={400} h={50} mx="auto">
        <div>
          <h2 className={globalStyles.subHeader}>
            Карта пациента №{patient.id}
          </h2>
        </div>
      </Center>
      <Space h="xl" />

      <Grid>
        <Grid.Col span= {7}>
          <Flex
            mih={50}
            gap="xs"
            justify="flex-start"
            align="flex-start"
            direction="column"
            wrap="wrap"
          >
            <Box>
              <label className={styles.textSize}>
                {patient.surname} {patient.name} {patient.patronymic}
              </label>
              <img
                src={Icons.Pencil}
                alt="Pencil icon"
                className={globalStyles.editButton}
                onClick={editPatient}
              />
              <img
                src={Icons.Trash}
                alt="Trash icon"
                className={globalStyles.deleteButton}
                onClick={opendeletePatientModal}
              />
              <Modal
                onClose={closeDeletePatientModal}
                opened={deletePatientModalOpened}
                centered
                withCloseButton={false}
              >
                <Box px="lg" py="sm">
                  <Text ta="center" fw={700}>
                    Вы действительно хотите удалить карту пациента{" "}
                    {getPatientShortName(patient)} из базы данных?
                  </Text>
                  <Space h="md" />
                  <Text ta="center">
                    Будут удалены все фотографии, снимки и отчеты по данному
                    пациенту.
                  </Text>
                  <Space h="xl" />
                  <Group position="apart">
                    <Button variant="default" onClick={closeDeletePatientModal}>
                      Отмена
                    </Button>
                    <Button variant="default" onClick={deletePatient}>
                      Удалить
                    </Button>
                  </Group>
                </Box>
              </Modal>
            </Box>

            <Box>
              <label className={styles.textSize}>Пол:</label>
              <label className={styles.textSize}>
                {patient.gender === "male"
                  ? "Мужской"
                  : patient.gender === "female"
                  ? "Женский"
                  : "-"}
              </label>
            </Box>

            <Box>
              <label className={styles.textSize}>Дата рождения:</label>
              <label className={styles.textSize}>
                {patient.dateOfBirth === "Invalid Date"
                ? "—"
              : new Date(patient.dateOfBirth).toLocaleDateString("ru-RU")}             
              </label>
            </Box>

            <Box>
              <label className={styles.textSize}>Клиника:</label>
              <label className={styles.textSize}>{clinicName}</label>
            </Box>

            <Box>
              <label className={styles.textSize}>Дата регистрации:</label>
              <label className={styles.textSize}>
                {new Date(patient.registrationDate).toLocaleDateString("ru-RU")}
              </label>
            </Box>

            <Box>
              <label className={styles.textSize}>Примечания:</label>
              <label className={styles.textSize}>{patient.comment}</label>
            </Box>

            <Box>
              <label className={styles.textSize}>Диагноз:</label>
              <label className={styles.textSize}>{patient.diagnosis}</label>
              <Space h="md" />
            </Box>

            <Box>
              <label className={styles.bigTextSize}>
                Загруженные фотопротоколы:
              </label>
              <Space h="md" />
            </Box>

            <Box>
              <label className={styles.greyText}>
                Нет загруженных фотопротоколов
              </label>
              <Space h="xl" />
            </Box>

            <Box>
              <label className={styles.bigTextSize}>Результаты ТРГ:</label>
              <Space h="md" />
            </Box>

            <Box>
              <label className={styles.greyText}>Нет загруженных ТРГ</label>
            </Box>
          </Flex>
        </Grid.Col>
        <Grid.Col span= "auto">
          <Stack spacing="xl" align="flex-end" justify="space-between" h={465}>
            <Space h="xl" />
            <Button
              radius={"xs"}
              fullWidth
              size="xl"
              variant="outline"
              className={styles.bigButton}
            >
              Новый фотопротокол
            </Button>
            <Button
              radius={"xs"}
              fullWidth
              size="xl"
              variant="outline"
              className={styles.bigButton}
            >
              Рассчитать ТРГ
            </Button>
            <Button
              radius={"xs"}
              fullWidth
              size="xl"
              variant="outline"
              className={styles.bigButton}
            >
              Cоздать презентацию
            </Button>
          </Stack>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default PatientCard;
