import React from "react";

import { ReactComponent as GearIcon } from "../../../assets/icons/gear.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as HumanIcon } from "../../../assets/icons/human.svg";

type IconProps = {
  name: "gearIcon" | "homeIcon" | "humanIcon";
};

const MyIcon = (props: IconProps) => {
  const { name, ...svgProps } = props;

  const Icons: Record<IconProps["name"], any> = {
    gearIcon: <GearIcon {...svgProps} />,
    homeIcon: <HomeIcon {...svgProps} />,
    humanIcon: <HumanIcon {...svgProps} />,
  };

  return Icons[name];
};

export default MyIcon;
