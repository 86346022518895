import React from "react";

import { CloseButton } from "@mantine/core";

import styles from "./Tab.module.scss";

type TabProps = {
  children: React.ReactNode;
  handleClose: (event: React.MouseEvent) => void;
  hasCloseButton: boolean;
};

const Tab = (props: TabProps) => {
  return (
    <div className={styles.tab}>
      {props.children}
      {props.hasCloseButton && (
        <CloseButton
          className={styles.tabCloseButton}
          onClick={(event) => props.handleClose(event)}
        ></CloseButton>
      )}
    </div>
  );
};

export default Tab;
