import React from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Icon from "../../UI/Icon/Icon";
import Tab from "../../UI/Tab/Tab";

import styles from "./Header.module.scss";
import { useActions, useAppSelector } from "../../../app/hooks";
import { Patient } from "../../../types/types";

import { useParams } from "react-router-dom";
import { getPatientShortName } from "../../../utils/utils";

const Header = () => {
  const { removeTab } = useActions();
  const { tabStore } = useAppSelector((state) => state);
  const navigate = useNavigate();

  const { id } = useParams();


  function closeTab(event: React.MouseEvent, tabId?: number) {
    event.preventDefault();
    if (!tabId) return;
    if (id && tabId === parseInt(id)) {
      navigate("/");
    }
    removeTab(tabId);
  }

  function mouseDownHandler(event: React.MouseEvent, tabId?: number) {
    if (!tabId) return;
    if (event.button === 1) {
      closeTab(event, tabId);
    }
  }

  return (
    <header className={styles.header}>
      <NavLink
        to={"/"}
        className={({ isActive }) => (isActive ? styles.tabActive : "")}
      >
        <Icon name="homeIcon"></Icon>
      </NavLink>
      {tabStore.tabs.map((tab) => {
        return (
          <NavLink
            key={tab.id}
            to={"/patient/" + tab.id}
            className={({ isActive }) => (isActive ? styles.tabActive : "")}
            onMouseDown={(event) => mouseDownHandler(event, tab.id)}
          >
            <Tab
              hasCloseButton={true}
              handleClose={(event: React.MouseEvent) => closeTab(event, tab.id)}
            >
              {tab.type === "patient" && getPatientShortName(tab as Patient)}
            </Tab>
          </NavLink>
        );
      })}
      <NavLink
        to="patient/create"
        className={({ isActive }) => (isActive ? styles.tabActive : "")}
      >
        <Tab
          hasCloseButton={false}
          handleClose={() => {
            return;
          }}
        >
          Новый пациент
        </Tab>
      </NavLink>
      <div className={styles.right}>
        <NavLink to="/settings">
          <Icon name="gearIcon" />
        </NavLink>
        <NavLink to={"/user"}>
          <Icon name="humanIcon" />
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
