import axios, { AxiosResponse } from "axios";

import { APIUser, APIToken } from "../types";

export const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_URL;

export function register(payload: {
  email: string;
  password: string;
}) {
  return axios.post("/users", payload) as Promise<AxiosResponse<APIUser>>;
}

export function auth(payload: { username: string; password: string }) {
  return axios.post("auth/token", payload, {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  }) as Promise<AxiosResponse<APIToken>>;
}
