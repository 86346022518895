import React from "react";
import { useNavigate } from "react-router-dom";
import { useActions, useAppDispatch } from "../../app/hooks";
import AuthenticationForm from "../../components/Auth/AuthForm";
import { Group } from "@mantine/core";
import { auth, register } from "../../api";

function LogIn() {
  
  return (
    <Group position="center" mt={"lg"}>
      <AuthenticationForm></AuthenticationForm>
    </Group>
  );
}

export default LogIn;
