import React, { useState } from "react";
import globalStyles from "../../App.module.scss";
import styles from "./User.module.scss";
import { EditUserInfoProps, User } from "../../types/types";
import { Button, Flex, Box } from "@mantine/core";

function EditUserInfo(props: EditUserInfoProps) {
    const [updatedUser, setUpdatedUser] = useState<User>({...props.user});

    const turnOffUserEditingMode = () => {
        props.setIsUserEdited(false);
    };

    const handleChangeUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedUser({
            ...updatedUser,
            [event.target.id]: event.target.value
        });
    };

    const saveUserInfo = () => {
        props.setUser(updatedUser);
        window.electron.user.update(updatedUser);
        turnOffUserEditingMode();
    };

    return (
      <div>
        <form className={styles.editForm}>
          <h3 className={globalStyles.subHeader}>Мои данные</h3>
          <div className={styles.formElement}>
            <label htmlFor="surname">Фамилия</label>
            <input
              defaultValue={props.user.surname}
              className={styles.inputField}
              id="surname"
              type="surname"
              onChange={handleChangeUserInfo}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="surname">Имя</label>
            <input
              defaultValue={props.user.name}
              className={styles.inputField}
              id="name"
              type="name"
              onChange={handleChangeUserInfo}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="patronymic">Отчество</label>
            <input
              defaultValue={props.user.patronymic}
              className={styles.inputField}
              id="patronymic"
              type="patronymic"
              onChange={handleChangeUserInfo}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="speciality">Специальность</label>
            <input
              defaultValue={props.user.speciality}
              className={styles.inputField}
              id="speciality"
              type="speciality"
              onChange={handleChangeUserInfo}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="email">E-mail</label>
            <input
              defaultValue={props.user.email}
              className={styles.inputField}
              id="email"
              type="text"
              onChange={handleChangeUserInfo}
            />
          </div>
        </form>
        <Flex gap="8rem">
          <Box w={120}>
            <Button
              radius={"xs"}
              fullWidth
              variant="outline"
              className={globalStyles.simpleButton}
              onClick={turnOffUserEditingMode}
            >
              Отмена
            </Button>
          </Box>
          <Box w={120}>
            <Button
              radius={"xs"}
              fullWidth
              variant="outline"
              className={globalStyles.simpleButton}
              onClick={saveUserInfo}
            >
              Сохранить
            </Button>
          </Box>
        </Flex>
      </div>
    );
}

export default EditUserInfo;