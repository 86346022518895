import Avatar from "../assets/avatar.svg";
import Pencil from "../assets/pencil_icon.svg";
import Trash from "../assets/trash_icon.svg";
import Plus from "../assets/icons8-plus.svg";
import Tick from "../assets/icons8-tick.svg";
import Close from "../assets/icons8-close.svg";

const Icons = {
  Avatar,
  Pencil,
  Trash,
  Plus,
  Tick,
  Close,
};

export default Icons;
