import { Patient, PatientWithClinicName } from "../types/types";

export function getPatientShortName(patient: Patient) {
  let result = "";
  result += patient.surname;
  if (patient.name) {
    result += " " + patient.name.at(0)?.toUpperCase() + ".";
  }

  if (patient.patronymic) {
    result += " " + patient.patronymic.at(0)?.toUpperCase() + ".";
  }
  return result;
}

export function getPatientFullName(patient: Patient | PatientWithClinicName) {
    let fullName = "";

    fullName += patient.surname;

    if (patient.name) {
        fullName += " " + patient.name;
    }

    if (patient.patronymic) {
        fullName += " " + patient.patronymic;
    }

    return fullName;
}


export function validateJWT(token?: string | null) {
  if (!token || token.split(".").length < 3) {
    return false;
  }

  try {
    const data = JSON.parse(atob(token.split(".")[1]));
    const exp = new Date(data.exp * 1000); // JS deals with dates in milliseconds since epoch
    const now = new Date();
    if (now > exp) return false;
  } catch {
    return false;
  }

  return true;
}